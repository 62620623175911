import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/layouts/post.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const YouTube = makeShortcode("YouTube");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`In the following few videos I will walk you through creating a GraphQL server using the `}<a parentName="p" {...{
        "href": "https://github.com/graphql/graphql-js"
      }}><inlineCode parentName="a">{`graphql.js`}</inlineCode></a>{` package.`}</p>
    <p>{`We will connect Express.js and Mongoose to power our GraphQL Schema, Query and Mutation.`}</p>
    <ul>
      <li parentName="ul">{`📺 `}<a parentName="li" {...{
          "href": "https://www.youtube.com/playlist?list=PLs2PzMqLzi7WkEKE9-DeVODZI63P4g6vk"
        }}>{`See full playlist`}</a></li>
      <li parentName="ul">{`🛠 `}<a parentName="li" {...{
          "href": "https://github.com/notrab/graphql-server-tutorial"
        }}>{`Browse code on GitHub`}</a></li>
    </ul>
    <h2>{`Part 1`}</h2>
    <p>{`In this video we'll get started creating our GraphQL server with Express.js, with an event type, and basic query.`}</p>
    <YouTube youTubeId="Igc6CznQHGU" mdxType="YouTube" />
    <h2>{`Part 2`}</h2>
    <p>{`In this video we'll explore fetching our events from Mongo using the argument `}<inlineCode parentName="p">{`id`}</inlineCode>{` in our `}<inlineCode parentName="p">{`Event`}</inlineCode>{` query.`}</p>
    <YouTube youTubeId="1z7cz1A5jzU" mdxType="YouTube" />
    <h2>{`Part 3`}</h2>
    <p>{`In this video we'll cover using GraphQL Mutations.`}</p>
    <YouTube youTubeId="3ZpO3Xn2QEI" mdxType="YouTube" />
    <h2>{`Part 4`}</h2>
    <p>{`In this video we'll create a query for getting all events.`}</p>
    <YouTube youTubeId="tcP-6KMqW2I" mdxType="YouTube" />
    <h2>{`Part 5`}</h2>
    <p>{`In this video we'll implement some basic GraphQL Subscriptions functionality.`}</p>
    <YouTube youTubeId="0xE2erxiIkE" mdxType="YouTube" />
    <h2>{`Part 6`}</h2>
    <p>{`In this video we'll explore refactoring some of our GraphQL server using the `}<inlineCode parentName="p">{`context`}</inlineCode>{`.`}</p>
    <YouTube youTubeId="yIKehW0fQic" mdxType="YouTube" />
    <h2>{`Part 7`}</h2>
    <p>{`In this video we'll explore implementing pagination for our events query.`}</p>
    <YouTube youTubeId="A_BKOMTeLRg" mdxType="YouTube" />
    <hr></hr>
    <p>{`Edit: Since recording this tutorial, there's a lot of new GraphQL tooling available, however this concept still stands, and a lot of production servers still use the `}<inlineCode parentName="p">{`graphql.js`}</inlineCode>{` implementation.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      